import React, { useState, useEffect } from "react";
import { Card, CardBody, CardTitle, Label, Spinner } from "reactstrap";
import { ColumnChart } from "./ColumnChart";
import { ZoomableMap } from "./ZoomableMap";
import { TimeSeries } from "./TimeSeries";
import { Auth } from "aws-amplify";
import { RainfallLegend } from "./RainfallLegend";
import { LandCover } from "./LandCover";
import { CSVLink } from "react-csv";
import { AiOutlineDownload } from "react-icons/ai";
import { Column } from "../Pricing/Column";
import { DualAxesChart } from "./DualAxesChart";
import { changeStateName } from "../../redux/action";
import { changeDistrictName } from "../../redux/action";
import { changeWardName } from "../../redux/action";
import { changeIUName } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { OlMap } from "./OlMap";



export const DataLibrary = () => {
  const [states, setStates] = useState(["AlQadarif"]);
  const stateName = useSelector((state) => state.stateName);
  const [districts, setDistricts] = useState([]);
  const districtName = useSelector((state) => state.districtName);
  const [wards, setWards] = useState([]);
  const wardName = useSelector((state) => state.wardName);
  const [ius, setIUs] = useState([]);
  const iuName = useSelector((state) => state.iuName);
  let [wardHover, setWardHover] = useState("");
  let [layer, setLayer] = useState(null);
  const [switchButton, setSwitchButton] = useState(false);
  const [ndviSeries, setNDVISeries] = useState([]);
  //const [ndviAnomaly, setNDVIAnomaly] = useState([]);
  const [monthlyRainfall, setMonthlyRainfall] = useState([]);
  const [yearlyRainfall, setYearlyRainfall] = useState([]);
  const [landCover, setLandCover] = useState([]);
  const [ndviTable, setNDVITable] = useState([]);
  const [seasonality, setSeasonality] = useState([]);
  const dispatch = useDispatch();


  useEffect(() => {
    let didCancel = false;
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();

      if (stateName !== null) {
        fetch("/api/climate/getDistrictNames?state_name=" + stateName, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            setDistricts(data);
          });
        if (districtName !== null) {
          fetch(
            "/api/climate/getWardNames?state_name=" +
            stateName +
            "&district_name=" +
            districtName,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: jwtToken,
              },
            }
          )
            .then((response) => response.json())
            .then((data) => {
              setWards(data);
            });
        }
        if (wardName !== null) {
          fetch(
            "/api/climate/getIUNames?state_name=" +
            stateName +
            "&district_name=" +
            districtName +
            "&ward_name=" +
            wardName,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: jwtToken,
              },
            }
          )
            .then((response) => response.json())
            .then((data) => {
              setIUs(data);
            });
        }

        if (wardName !== null) {
          fetch("/api/climate/monthlyRainfall?state_name=" + stateName + "&district_name=" + districtName + "&ward_name=" + wardName  + "&iu_name=" + iuName , {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          })
            .then((response) => response.json())
            .then((data) => {
              setMonthlyRainfall(data);
            });
          fetch("/api/climate/yearlyRainfall?state_name=" + stateName + "&district_name=" + districtName + "&ward_name=" + wardName + "&iu_name=" + iuName , {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          })
            .then((response) => response.json())
            .then((data) => {
              setYearlyRainfall(data);
            });
          fetch("/api/climate/historicalNDVI?state_name=" + stateName + "&district_name=" + districtName + "&ward_name=" + wardName  + "&iu_name=" + iuName, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          })
            .then((response) => response.json())
            .then((data) => {
              setNDVISeries(data);
            });
          /*fetch("/api/climate/anomalyNDVI?state_name=" + stateName + "&district_name=" + districtName + "&ward_name=" + wardName  + "&iu_name=" + iuName, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          })
            .then((response) => response.json())
            .then((data) => {
              setNDVIAnomaly(data);
            });
            */
          fetch("/api/climate/landCoverPercent?state_name=" + stateName + "&district_name=" + districtName + "&ward_name=" + wardName  + "&iu_name=" + iuName , {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          })
            .then((response) => response.json())
            .then((data) => {
              setLandCover(data);
            });
          fetch("/api/climate/ndviRainfallGraph?state_name=" + stateName + "&district_name=" + districtName + "&ward_name=" + wardName  + "&iu_name=" + iuName, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          })
            .then((response) => response.json())
            .then((data) => {
              setSeasonality(data);
            });
        }
      }
      if (!didCancel) {
        // Ignore if we started fetching something else
        console.log("....");
      }
    }
    genToken();
    return () => {
      didCancel = true;
    };
  }, [stateName, districtName, wardName, layer, iuName]);

  const handleDownloadHistoricalNdvi = () => {
    let didCancel = false;
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();

      fetch("/api/climate/historicalNDVIDownload?state_name=" + stateName + "&district_name=" + districtName + "&ward_name=" + wardName  + "&iu_name=" + iuName, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create a temporary URL for the blob
          const url = URL.createObjectURL(blob);

          // Create a link element
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${wardName}_Ndvi.xlsx`);

          // Append the link to the document body and trigger the download
          document.body.appendChild(link);
          link.click();

          // Clean up the temporary URL and link element
          URL.revokeObjectURL(url);
          document.body.removeChild(link);
        })
        .catch((error) => {
          // Handle the error
          console.error("Error:", error);
        });
      if (!didCancel) {
        console.log("....");
      }
    }
    genToken();
    return () => {
      didCancel = true;
    };
  };


  let stateObject = [];
  let districtObject = [];
  let wardObject = [];
  let iuObject = [];

  for (let j in states) {
    let items = {};
    items["label"] = states[j];
    items["value"] = states[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    stateObject.push(json_obj);
  }
  for (let j in districts) {
    let items = {};
    items["label"] = districts[j];
    items["value"] = districts[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    districtObject.push(json_obj);
  }
  for (let j in wards) {
    let items = {};
    items["label"] = wards[j];
    items["value"] = wards[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    wardObject.push(json_obj);
  }
  for (let j in ius) {
    let items = {};
    items["label"] = ius[j];
    items["value"] = ius[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    iuObject.push(json_obj);
  }
  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 28,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: 4,
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "0px 6px",
    }),
  };


  return (
    <>
      <div
        className="row justify-content-center"
        style={{
          backgroundColor: "#EFEFEF",
          minHeight: "calc(100vh - 80px)",
        }}
      >
        <div className="col-md-6">
          <div className="row m-1">
            <div className="col-md-12">
              <Card
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#f5f5f5",
                }}
              >
                {/*<OlMap ward={wardName} state={stateName} />*/}
                <ZoomableMap
                  wardHover={wardHover}
                  setWardHover={setWardHover}
                  setLayer={setLayer}
                  layer={layer}
                  setSwitchButton={setSwitchButton}
                  switchButton={switchButton}
                />
                <div className="row justify-content-center mt-1">
                  <div className="col-md-12">
                    {layer !== null ? (
                      <>
                        <div className="row justify-content-center">
                          <div
                            className="col-md-2 mt-1"
                            style={{ fontSize: "12px", fontWeight: "bold" }}
                          >
                            {layer}
                          </div>
                          <div className="col-md-3">
                            {layer === "Rainfall" ? (
                              <>
                                <RainfallLegend />
                              </>
                            ) : layer === "Landcover" ? (
                              <>
                                <LandCover />
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <Card
            className="m-1"
            body
            style={{
              backgroundColor: "#fff",
              borderColor: "#f5f5f5",
            }}
          >
            <div className="row justify-content-center">
              <div className="col-md-2" style={{ fontSize: "12px" }}>
                <Label>Select State</Label>
                <Select
                  options={stateObject}
                  value={{
                    value: stateName,
                    label: stateName,
                  }}
                  onChange={(obj) => {
                    dispatch(changeStateName(obj["value"]));
                    dispatch(changeDistrictName(null));
                    dispatch(changeWardName(null));
                  }}
                  required
                  styles={customStyles}
                />
              </div>{" "}
              <div className="col-md-2" style={{ fontSize: "12px" }}>
                <Label>Select District</Label>
                <Select
                  options={districtObject}
                  value={{
                    value: districtName,
                    label: districtName,
                  }}
                  onChange={(obj) => {
                    dispatch(changeDistrictName(obj["value"]));
                    dispatch(changeWardName(null));
                  }}
                  required
                  styles={customStyles}
                />
              </div>{" "}
              <div className="col-md-2" style={{ fontSize: "12px" }}>
                <Label>Select Locality</Label>
                <Select
                  options={wardObject}
                  value={{
                    value: wardName,
                    label: wardName,
                  }}
                  onChange={(obj) => dispatch(changeWardName(obj["value"]))}
                  required
                  styles={customStyles}
                />
              </div>{" "}
              <div className="col-md-2" style={{ fontSize: "12px" }}>
                <Label>Select IU Name</Label>
                <Select
                  options={iuObject}
                  value={{
                    value: iuName,
                    label: iuName,
                  }}
                  onChange={(obj) => dispatch(changeIUName(obj["value"]))}
                  required
                  styles={customStyles}
                />
              </div>{" "}
            </div>
            {wardName !== null ? (
              <>
                <CardBody className="scroll">
                  <CardTitle
                    tag="h6"
                    style={{ color: "#0039E6" }}
                    className="text-center mt-5"
                  >
                    Land Cover{" "}
                  </CardTitle>
                  <div className="row justify-content-center">
                    <div className="col-md-10">
                      <Column data={landCover} type={"Data"} yAxis={"%"} />
                    </div>
                  </div>

                  <CardTitle
                    tag="h6"
                    style={{ color: "#0039E6" }}
                    className="text-center mt-2"
                  >
                    CHIRPS Monthly Precipitation{" "}
                    <CSVLink
                      data={monthlyRainfall}
                      filename={wardName + "_Monthly_Rainfall.csv"}
                    >
                      <div
                        className="shadow"
                        style={{
                          borderRadius: "50%",
                          borderColor: "#0039E6",
                          backgroundColor: "#0039E6",
                          height: "22px",
                          width: "22px",
                          justifyContent: "center",
                          display: "inline-flex",
                        }}
                      >
                        <AiOutlineDownload
                          size={16}
                          style={{ color: "#fff", marginTop: "4px" }}
                        />
                      </div>
                    </CSVLink>
                  </CardTitle>
                  <ColumnChart
                    data={monthlyRainfall}
                    type={"Data"}
                    ward={wardName}
                  />
                  <CardTitle
                    tag="h6"
                    style={{ color: "#0039E6" }}
                    className="text-center mt-5"
                  >
                    CHIRPS Yearly Precipitation{" "}
                    <CSVLink
                      data={yearlyRainfall}
                      filename={wardName + "_Yearly_Rainfall.csv"}
                    >
                      <div
                        className="shadow"
                        style={{
                          borderRadius: "50%",
                          borderColor: "#0039E6",
                          backgroundColor: "#0039E6",
                          height: "22px",
                          width: "22px",
                          justifyContent: "center",
                          display: "inline-flex",
                        }}
                      >
                        <AiOutlineDownload
                          size={16}
                          style={{ color: "#fff", marginTop: "4px" }}
                        />
                      </div>
                    </CSVLink>
                  </CardTitle>
                  <ColumnChart
                    data={yearlyRainfall}
                    type={"Data"}
                    ward={wardName}
                  />
                  <CardTitle
                    tag="h6"
                    style={{ color: "#0039E6" }}
                    className="text-center mt-5"
                  >
                    NDVI Timeseries
                    <div
                      className="shadow m-1"
                      style={{
                        borderRadius: "50%",
                        borderColor: "#0039E6",
                        backgroundColor: "#0039E6",
                        height: "22px",
                        width: "22px",
                        justifyContent: "center",
                        display: "inline-flex",
                      }}
                      onClick={handleDownloadHistoricalNdvi}
                    >
                      <AiOutlineDownload
                        size={16}
                        style={{ color: "#fff", marginTop: "4px" }}
                      />
                    </div>
                  </CardTitle>

                  <TimeSeries
                    data={ndviSeries}
                    ward={wardName}
                    type={"ndvi"}
                  />
                  {/*<CardTitle
                    tag="h6"
                    style={{ color: "#0039E6" }}
                    className="text-center mt-5"
                  >
                    NDVI Anomaly
                    <CSVLink data={ndviAnomaly} filename={"ndvi_anomaly.csv"}>
                      <div
                        className="shadow m-1"
                        style={{
                          borderRadius: "50%",
                          borderColor: "#0039E6",
                          backgroundColor: "#0039E6",
                          height: "22px",
                          width: "22px",
                          justifyContent: "center",
                          display: "inline-flex",
                        }}
                      >
                        <AiOutlineDownload
                          size={16}
                          style={{ color: "#fff", marginTop: "4px" }}
                        />
                      </div>
                    </CSVLink>
                  </CardTitle>

                  <TimeSeries
                    data={ndviAnomaly}
                    ward={wardName}
                    type={"anomaly"}
                  />
                  */}

                  <CardTitle
                    tag="h6"
                    style={{ color: "#0039E6" }}
                    className="text-center mt-5"
                  >
                    Seasonality
                  </CardTitle>

                  <DualAxesChart data={seasonality} ward={wardName} />
                </CardBody>
              </>
            ) : (
              <>
                <h6 className="mt-2">Please click on a ward</h6>
              </>
            )}
          </Card>
        </div>
      </div>
    </>
  );
};
